<template>
        <main-template :mode="mode" @toggleMode="toggleMode" @setMode="toggleMode" @setClientId="setClientId">
            <template v-slot:headerLottieDark>
                <dotlottie-player :src="getDarkLogo()"
                                  background="transparent" speed="1"  style="width: 450px; height: 80px" direction="1" playMode="normal"  no-autoplay></dotlottie-player>
            </template>
            <template v-slot:headerLottieLight>
                <dotlottie-player :src="getLightLogo()"
                                  background="transparent" speed="1"  style="width: 450px; height: 80px" direction="1" playMode="normal" ></dotlottie-player>
            </template>
            <template v-slot:header>Hub</template>
            <template slot-scope="{logo}">
            <fetch-siren-root :linkName='linkName' :link="link">

                <div class="flex flex-grow overflow-hidden" slot-scope="{ response }">
                    <loading v-if="!response"></loading>
                    <template v-else>
<!--                            <onboarding-steps v-if="unfinishedOnboardingSteps.length > 0" :completeSteps="finishedOnboardingSteps" :incompleteSteps="unfinishedOnboardingSteps"></onboarding-steps>-->
<!--                            <dashboard-modal></dashboard-modal>-->

<!--                            <advert :mode="mode"></advert>-->

<!--                            <drag-drop-widgets :editable="editable" :mode="mode"></drag-drop-widgets>-->
                            <div class="flex h-full w-full overflow-hidden">
                                <div class="flex w-full h-full p-2">
                                    <div class="rounded-xl bg-v3-white-100 w-full h-full margin-10 dark:bg-v3-gray-900">
                                        <default-dashboard ref="defaultDashboard"></default-dashboard>
                                    </div>
                                </div>
                            </div>
                        <large-model v-if="showWelcomeVideo" :displayConfirmButton="false" @removeFromDisplay="closeWelcomeVideo">
                            <h2>Welcome to the new CMS Desk</h2>
                            <div class="h-full">
                                <div class="w-full h-full">
                                    <iframe class="w-full" width="560" height="450" src="https://www.youtube.com/embed/FmJ_8bMlYtQ?si=D2UG6PnigEJFN6Qn&autoplay=1" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                                </div>
                            </div>
                            <div>
                                <input id="txtDontShow" type="checkbox" v-model="dontShowAgain"/>
                                <label class="ml-2 dark:text-v3-white-100 text-v3-gray-900" for="txtDontShow">Don't show again</label>
                            </div>
                        </large-model>
                    </template>
                </div>

            </fetch-siren-root>
            </template>



        </main-template>
</template>

<script>
import FetchSirenRoot from '@/components/FetchSirenRoot';
import Loading from '@/components/Loading';
import DashboardStats from '@/components/Dashboard/DashboardStats';
import SavedFilters from '@/components/SavedFilters';
import DashboardHeader from '@/components/Headers/DashboardHeader';
import MainTemplate from '@/v3/templates/MainTemplate';
import OnboardingSteps from "@/components/OnboardingSteps";
import {mapGetters} from "vuex";
import Siren from "super-siren";
import DashboardModal from "@/components/Dashboard/DashboardModal";
import DragDropWidgets from "@/components/DragDropWidgets";
import PageHeader from "@/v3/components/headers/PageHeader.vue";
import Button from "@/v2/buttons/Button";
import SlideOver from "@/v2/components/SlideOver";
import Advert from "@/v2/components/Advert";
import DefaultDashboard from "@/v3/components/DefaultDashboard.vue";
import HqNavigation from "@/v3/components/navigation/HqNavigation.vue";
import LargeModel from "@/v3/models/LargeModel.vue";
import axios from "axios";
export default {
    components: {
        LargeModel,
        HqNavigation,
        DefaultDashboard,
        Advert,
        OnboardingSteps,
        FetchSirenRoot,
        Loading,
        DashboardStats,
        DashboardHeader,
        SavedFilters,
        MainTemplate,
        DashboardModal,
        DragDropWidgets,
        PageHeader,
        'a-button' : Button,
        SlideOver
    },
    data() {
        return {
            onboardingSteps: [],
            editable: false,
            backgroundImage: null,
            backgroundImageUrl: null,
            hasOssModule: false,
            displayModal: false,
            widgetTypeValues: null,
            inputValue:null,
            mode: 'light',
            showWelcomeVideo: false,
            dontShowAgain: false
        }
    },
    computed: {
        ...mapGetters({
            accessToken: "getAccessToken",
            user: "getUser"
        }),
        linkName() {
            return null;
        },
        link(){
            // if(Object.keys(this.$route.query).length === 0){
            //     return '/orders';
            // }
            return this.$route.fullPath.replace('/v3','')
        },
        unfinishedOnboardingSteps() {
            return this.onboardingSteps.filter(step => step.done !== true)
        },
        finishedOnboardingSteps() {
            return this.onboardingSteps.filter(step => step.done === true)
        },

    },
    mounted() {
        console.log(this.user['dont_show_video']);
        if(this.user['dont_show_video'] !== 1 && this.user['dont_show_video'] !== true){
            this.showWelcomeVideo = true;
            //to be removed once all users are updated
            axios.get(process.env.VUE_APP_API_URL + '/api/users/' + this.user['id'],{
                headers: {Authorization: `Bearer ${this.accessToken}`}
            })
            .then(response=>{
                this.$store
                    .dispatch("setUser", {
                        user: response.data.properties
                    });
                if(response.data.properties['dont_show_video'] !== 1 && response.data.properties['dont_show_video'] !== true) {
                    this.showWelcomeVideo = true;
                }else{
                    this.showWelcomeVideo = false;
                }
            });

        }
    },
    methods: {
        toggleMode(value){
            this.mode = value;
        },
        getDarkLogo()
        {
            return process.env.VUE_APP_API_URL + "/api/json/lottie?type=desk_dark"
        },
        getLightLogo()
        {
            return process.env.VUE_APP_API_URL + "/api/json/lottie?type=desk_light"
        },
        closeWelcomeVideo()
        {
            if(this.dontShowAgain === true){
                axios.post(process.env.VUE_APP_API_URL + '/api/welcome-video/dont-show', {
                    'dont-show': this.dontShowAgain
                }, {
                    headers: {Authorization: `Bearer ${this.accessToken}`}
                }).then(()=>{
                    axios.get(process.env.VUE_APP_API_URL + '/api/users/' + this.user['id'],{
                        headers: {Authorization: `Bearer ${this.accessToken}`}
                    })
                    .then(response=>{
                        this.$store
                            .dispatch("setUser", {
                                user: response.data.properties
                            });
                    });
                });
            }
            this.showWelcomeVideo = false;

        },
        setClientId(id){
            this.$refs.defaultDashboard.loadOssDetails(id);
        }
    }
}
</script>

<style lang="scss" scoped>

</style>
