<template>
    <div>
        <div class="sm:hidden">
            <label for="tabs" class="sr-only">Select a tab</label>
            <!-- Use an "onChange" listener to redirect the user to the selected tab URL. -->
            <select id="tabs" @change="navigateTo('456')" name="tabs" class="block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md">
                <option value="123" :selected="link.title.includes('active')" v-for="link in savedFilters">
                    {{link.title.replace(' (active)', '')}}
                </option>
            </select>
        </div>
        <div class="hidden sm:block">
            <div class="">
                <nav class="flex space-x-8 ml-6 overflow-auto" aria-label="Tabs">
                    <a @click="navigateTo(link.href)" href="#" v-for="link in savedFilters"
                       class="border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300 whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm"
                       :class="{'text-secondary-lightblue border-secondary-lightblue focus:secondary-lightblue focus:secondary-lightblue': link.title.includes('active')}"
                    >
                            {{link.title.replace(' (active)', '')}}
                    </a>
                    <a v-if="!hasMatchingSavedFilter() && savedFilters && savedFilters.size > 0"
                       class="whitespace-nowrap ml-8 py-4 px-1 border-b-2 font-medium text-sm leading-5 focus:outline-none text-blue-600 border-blue-500 focus:text-blue-800 focus:border-blue-700"
                    >Untitled search</a>
                </nav>
            </div>
        </div>

    </div>
</template>

<script>
export default {
    name: "OrderTabs",
    props: {
        response: {
            type: Object,
        },
    },
    computed: {
        savedFilters(){
            return this.response.body.links.filter(link => link.rels.toString().includes('saved_filter'));
        },

    },
    methods: {
        navigateTo(link){
            window.location = this.getFrontendRouteFromApiRoute(link);
            //     this.$router.go(link.replace('/api', ''));
        },
        hasMatchingSavedFilter(){
            if(!this.savedFilters){
                return false;
            }
            let links = this.savedFilters.filter(link => link.title.includes('active'));
            return links.size;
        },
    }
}
</script>

<style scoped>

</style>
