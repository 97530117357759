<template>
    <div class="rounded-md flex-grow relative">
        <div class="focus-within:z-10">
            <label for="email" class="block text-sm font-medium leading-5 text-v3-gray-900 dark:text-white mt-3">{{field.title}}</label>
            <div class="form-input relative">
                <input
                    :name="field.name"
                    class="form-input focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-0 p-1 rounded-md bg-v3-gray-900 bg-opacity-5 text-v3-gray-900 text-opacity-70 placeholder-v3-gray-900 placeholder-opacity-70 dark:bg-white dark:bg-opacity-5 dark:text-white dark:text-opacity-70 dark:placeholder-white dark:placeholder-opacity-70"
                    :placeholder="field.title"
                    v-model="propsVal"
                    :type="!showPassword ? 'password' : 'text'"
                    @change="change"
                    @blur="blur"
                    ref="input"
                />
                <div class="absolute inset-y-0 right-0 pr-3 flex items-center cursor-pointer" @click="togglePassword">
                    <svg class="h-4 w-4 text-v3-gray-900 text-opacity-70 dark:text-white dark:text-opacity-70" fill="currentColor" viewBox="0 0 20 20">
                        <svg v-if="!showPassword" class="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"></path><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"></path></svg>
                        <svg v-if="showPassword" class="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13.875 18.825A10.05 10.05 0 0112 19c-4.478 0-8.268-2.943-9.543-7a9.97 9.97 0 011.563-3.029m5.858.908a3 3 0 114.243 4.243M9.878 9.878l4.242 4.242M9.88 9.88l-3.29-3.29m7.532 7.532l3.29 3.29M3 3l3.59 3.59m0 0A9.953 9.953 0 0112 5c4.478 0 8.268 2.943 9.543 7a10.025 10.025 0 01-4.132 5.411m0 0L21 21"></path></svg>
                    </svg>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            value: {
                type: String
            },
            field: {
                type: Object
            },
        },
        data() {
            return {
                propsVal: this.value,
                showPassword: false
            }
        },
        methods: {
            change() {
                this.$emit("change");
            },
            blur() {
                this.$emit("blur");
            },
            updateVal(val) {
                this.propsVal = val;
            },
            togglePassword(){
                this.showPassword = !this.showPassword
            }
        },
        watch: {
            propsVal: {
                handler(value) {
                    this.$emit("input", value);
                },
                deep: true
            },
        },
    }
</script>

<style lang="scss" scoped>

</style>
