<template>
    <div class="cursor-default">
        <custom-button :class="additionalButtonClasses" v-if="isButton" @click.native="displayModal = true" :colour-type="buttonType">
            {{title ? title : action.title}}
        </custom-button>

      <a v-else class="cursor-pointer font-normal"  @click="displayModal = true" :data-cy="`action-button-${action.name}`">
        {{title ? title : action.title}}
      </a>
        <portal to="destination">
            <large-model v-show="displayModal" :submitting="submittingModal" :title="action.title"
                        :confirmationString='`Are you sure you want to \"${action.title}\"`'
                        @removeFromDisplay="displayModal = false" @perform="perform()" :confirm-button-text="title ? title : action.title">
                <template v-for="(field, index) in action.fields.sort((a, b) => {
                    if (a === null) {
                        return 1;
                    }
                    if (b === null) {
                        return -1;
                    }
                    return a.sort - b.sort;
                })">
                    <component :is="getFieldComponent(field[1])" :field="field[1]" v-model="formData[field[1].name]">
                    </component>
                    <div v-show="errors.has(field[1].name)" class="text-red-600 text-sm">{{ errors.first(field[1].name) }}</div>
                </template>

                <div v-show="errors.has('items')" class="text-red-600 text-sm">{{ errors.first('items') }}</div>

            </large-model>
        </portal>
    </div>
</template>

<script>
import SlideOver from "@/v2/components/SlideOver";
import Button from "@/v2/buttons/Button";
import fileDownload from "js-file-download";
import LargeModel from "@/v3/models/LargeModel.vue";
import PropertyOrField from "@/components/PropertyOrField.vue";
import BlankHeader from "@/v3/components/headers/BlankHeader.vue";
import Header from "@/v2/components/Header.vue";
import CollapsableHeader from "@/v3/components/headers/CollapsableHeader.vue";
import Siren from "super-siren";
import {mapGetters} from "vuex";
import CustomButton from "@/v3/Buttons/CustomButton.vue";
let follow = Siren.Helper.follow;
export default {

    inject: ["$validator"],
    name: "CreateOrderForm",
    data() {
        return {
            displayModal: false,
            submittingModal: false,
            clientId: null,
            projectId: null,
            deliveryMethod: 'delivery'
        }
    },
    components: {
        CustomButton,
        CollapsableHeader,
        Header,
        BlankHeader,
        PropertyOrField,
        LargeModel,
        SlideOver,
        'a-button': Button
    },
    props: {
        action: {
            type: Object,
        },
        actionKey: {
            type: String
        },
        onSuccessPath: {
            type: String
        },
        redirectOnSuccess: {
            type: Boolean,
            default: true
        },
        title: {
            type: String,
            default: null
        },
        additionalButtonClasses: {
            type : String,
            default: null
        },
        buttonType:{
            type: String,
            default: 'primary'
        },
      isButton:{
          type: Boolean,
          default: true
      }
    },
    computed: {
        ...mapGetters({
            accessToken: "getAccessToken"
        }),
        formData() {
            if (!this.action.fields) {
                return {};
            }
            var payload = {};
            this.action.fields.forEach(function (field) {
                if (field.value !== undefined) payload[field.name] = field.value;
            });
            // if(payload['client_id'] === undefined)
            // {
            //     Siren.Client.addHeader("Authorization", `Bearer ${this.accessToken}`);
            //     Siren.get(process.env.VUE_APP_API_URL + "/api/")
            //         .then(
            //             follow("current-user")
            //         )
            //         .then(res => {
            //             this.clientId = res.body.properties.get("client_id");
            //         }, this);
            // }
            return payload;
        },
    },
    methods: {
        getFieldComponent(field) {
            if(field.title.includes("(GBP)")){
                return 'gbp-input';
            }

            return field.type + '-input';
        },
        perform() {
            if(!this.submittingModal) {
                this.submittingModal = true;
                this.action.perform(this.formData).then(res => {
                    if (res.type === 'text/csv') {
                        fileDownload(res.text, 'export.csv');
                        this.submittingModal = false;
                        return;
                    }
                    this.displayModal = false;
                    this.submittingModal = false;
                    this.$store.dispatch(
                        "setMessage",
                        this.messages(res).entity.properties.get("success")
                    );
                    if (this.redirectOnSuccess) {
                        this.$router.go(0);
                    }
                    this.$emit('success', res);
                }).catch(error => {
                    this.submittingModal = false;
                    if (error.status === 422) {
                        this.$setLaravelValidationErrorsFromResponse(
                            JSON.parse(error.response.text)
                        );
                    } else if (error.status === 403 || error.status === 401) {
                        let errorMessage = "You do not have permission to " + this.action.title;
                        if (error.response.body.message) {
                            errorMessage = error.response.body.message;
                        }
                        alert(errorMessage);
                    } else {
                        alert('There was an error processing your request.' + error);
                    }
                    this.$emit('failure');
                });
            }
        },
        messages(response) {
            return response.body.entities
                .filter(function (entity) {
                    return entity.rels.contains("status-messages");
                })
                .first();
        }
    },
}
</script>

<style scoped>

</style>
